import React from 'react'
import * as s from '../styles/newhero.module.scss'
import img from '../images/store/gallery_0.jpg'

export const NewHero = () => {
  return (
    <>
      <div className={s.outer_container}>
        <div className={s.image_wrapper}>
          <div className={s.image_wrapper_inner}>
            <img src={img} alt='Interior of the Solana Spaces Store' />
          </div>
        </div>
        <div className={s.container}>
          <h2 className={s.extra_large}>Thank you for everything.</h2>
          <p className={s.subtitle}>
            Build Solana in your city at{' '}
            <a href='https://spacesdao.org'>spacesdao.org</a> or visit us at{' '}
            <a href='https://drip.haus'>drip.haus</a>.
          </p>
        </div>
      </div>
    </>
  )
}
