import React from 'react'
import Layout from '../layouts'
import { NewHero } from '../components/NewHero'
import { PageProps } from 'gatsby'

const IndexPage = ({ path }: PageProps) => {
  return (
    <>
      <Layout path={path} title='Home'>
        <NewHero />
      </Layout>
    </>
  )
}

export default IndexPage
